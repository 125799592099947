const convertDeptToColor = (department) => {
  switch (department) {
    case 'Management':
      return 'ragnarok';
    case 'Research':
      return 'terra';
    case 'Marketing':
      return 'nebula';
    case 'Design':
      return 'coral';
    case 'Sales':
      return 'qwerty';
    case 'Engineering':
      return 'arcade';
    case 'Product':
      return 'midgard';
    case 'HR':
      return 'core-light';
    case 'Finance':
      return 'pebble';
    default:
      return 'core';
  }
};

export default convertDeptToColor;
