import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Columns, Content, Rows } from "../../components/grid"
import convertDeptToColor from "../../utils/convertDeptToColor"

function TeamMemberCard({ jobTitle, name, department, avatar, pronouns }) {
  const img = getImage(avatar.localFile)
  const tagColor = convertDeptToColor(department)

  return (
    <div className="team-member">
      <Rows>
        <Columns count="1">
          <Content paddingRight="none" paddingLeft="none">
            <div
              className={`team-member__avatar team-member__avatar--${tagColor}`}
            >
              <div className="team-member__moon" />
              <GatsbyImage image={img} alt={avatar.alternativeText} />
            </div>
            <Rows gap="xxs" className="team-member__details">
              <p className="text--xs text--color-grey">{pronouns}</p>
              <p className="h4 team-member__name">{name}</p>
              <p>{jobTitle}</p>
            </Rows>
          </Content>
        </Columns>
      </Rows>
    </div>
  )
}

export default TeamMemberCard
