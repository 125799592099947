import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/seo/seo"
import Main from "../../components/layout/main"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import { Columns, Content, Footer, Rows } from "../../components/grid"
import Heading from "../../components/content/heading"
import Copy from "../../components/content/copy"
import CtaGroup from "../../components/containers/cta-group"
import Button from "../../components/interactive/button"
import SectionBackground from "../../components/containers/section-background"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft, faHeart } from "@fortawesome/free-solid-svg-icons"
import TryUsBar from "../../components/sections/try-us-bar"
import TeamMemberCard from "@src/components-smart/carousel/team-card"

function AboutPage({ data, location }) {
  const {
    header,
    seo,
    fullyManaged,
    columns,
    treePlanting,
    opportunities,
    ourStory,
    meetTheTeam,
  } = data.strapiPageAbout

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: location && location.pathname,
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <header>
        <Section className="section--tall" theme="core">
          <SectionBackground>
            <div style={{ position: "absolute", right: 100, bottom: 0 }}>
              <StaticImage
                src="../../images/about-us/about-us-red.png"
                alt="Red"
              />
            </div>
          </SectionBackground>
          <SectionContent hero paddingBot="lg" paddingTop="xl">
            <Rows gap="xs" className="mb-xl">
              <Columns count="1" alignment="center">
                <Content paddingRight="none">
                  <h1 style={{ fontSize: 100, lineHeight: "128px" }}>
                    Hey 👋 <br /> We're Lunio.
                  </h1>
                </Content>
              </Columns>
              <Columns count="2">
                <Content paddingRight="none" gap="xl">
                  <Copy>
                    <p>{header.postHeadingText}</p>
                  </Copy>
                  <Footer>
                    <CtaGroup>
                      {header.buttons.map(
                        ({ color, link, text, type }, index) => (
                          <Button
                            key={`home-heading-button-${index}`}
                            href={link}
                            styletype={type}
                            color={color}
                          >
                            {text}
                          </Button>
                        ),
                      )}
                    </CtaGroup>
                  </Footer>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingBot="xs">
          <Rows>
            <Columns count="1,2" className="mt-xxl">
              <Content>
                <Heading>{fullyManaged.heading.primaryHeading}</Heading>
                <Copy>
                  <p>{fullyManaged.heading.preHeading}</p>
                </Copy>
              </Content>
              <Content>
                <Rows gap="xs" className="tab-mx-md">
                  <div className="relative">
                    <FontAwesomeIcon
                      className="absolute text--color-coral h2 hidden--tab-down"
                      style={{ left: -58, top: -8 }}
                      icon={faQuoteLeft}
                    />
                    <p class="h2 text-gradient text-gradient__coral">
                      We believe digital marketing should be built on trust,
                      transparency, and genuine human interactions
                    </p>
                  </div>
                  <div className="flex flex--align-center">
                    <p className="text--strong">Neil Andrew | CEO</p>
                  </div>
                </Rows>
              </Content>
            </Columns>
            <Columns count="3" className="mt-md mb-md">
              {columns.map(({ text, title }, index) => (
                <Content key={index}>
                  <Heading level={3}>{title}</Heading>
                  <Copy contentToParse={text} />
                </Content>
              ))}
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingTop="md" paddingBot="md">
          <div className="py-md px-md tab-py-xl border--rounded tab-px-xxl text-gradient__midgard relative">
            <div
              className="absolute hidden--tab-down"
              style={{ width: 220, top: -120, left: -120 }}
            >
              <StaticImage src="../../images/about-us/Poloroid-2.png" />
            </div>
            <div
              className="absolute hidden--lap-down"
              style={{ width: 260, bottom: -125, right: -160 }}
            >
              <StaticImage src="../../images/about-us/Poloroid-3.png" />
            </div>
            <Rows>
              <Columns count="1" alignment="center">
                <Content paddingLeft="none" paddingRight="none">
                  <Heading className="text--color-white" alignment="center">
                    {opportunities.title.primaryHeading}
                  </Heading>
                </Content>
                <Content>
                  <CtaGroup alignment="center">
                    <Button
                      href={opportunities.button.link}
                      styletype="blank"
                      color="light"
                    >
                      {opportunities.button.text}
                    </Button>
                  </CtaGroup>
                </Content>
              </Columns>
            </Rows>
          </div>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent>
          <Rows gap="lg">
            <Columns count="1,2" className="mt-xxl grid__columns--team-outer">
              <Content>
                <Heading>{meetTheTeam.title.primaryHeading}</Heading>
                <Copy>
                  <p>{meetTheTeam.title.preHeading}</p>
                </Copy>
              </Content>
              <Rows gap="lg">
                <Columns className="grid__columns--team">
                  {meetTheTeam.teamListing.map(
                    ({ jobTitle, name, avatar, pronouns }, index) => (
                      <Content key={index}>
                        <TeamMemberCard
                          jobTitle={jobTitle}
                          name={name}
                          avatar={avatar}
                          pronouns={pronouns}
                        />
                      </Content>
                    ),
                  )}
                </Columns>
              </Rows>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingBot="lg">
          <div className="border--rounded text-gradient__nebula relative overflow--hidden">
            <Rows>
              <Columns count="2">
                <Content
                  className="pl-sm pr-sm py-md tab-pl-xxl tab-py-xl tab-pr-none"
                  style={{ "--text-color": "#ffffff" }}
                >
                  <Heading level={3}>
                    {treePlanting.heading.primaryHeading}
                  </Heading>
                  <Copy contentToParse={treePlanting.text} />
                </Content>
                <div className="hidden--tab-down">
                  <div className="about-images__dot">
                    <StaticImage src="../../images/about-us/lunio-dot.png" />
                  </div>
                  <div className="about-images__globe">
                    <StaticImage
                      src="../../images/about-us/lunio-earth.png"
                      alt="carbon neutral planet earth"
                    />
                  </div>
                </div>
              </Columns>
            </Rows>
          </div>
        </SectionContent>
      </Section>
    </Main>
  )
}

export const query = graphql`
  query AboutPageQuery {
    strapiPageAbout {
      seo {
        metaTitle
        metaDesc
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      header {
        headingText
        postHeadingText
        buttons {
          color
          link
          type
          text
        }
      }
      fullyManaged {
        heading {
          preHeading
          primaryHeading
        }
      }
      columns {
        text
        title
      }
      treePlanting {
        heading {
          primaryHeading
        }
        text
      }
      opportunities {
        title {
          primaryHeading
        }
        button {
          color
          link
          text
          type
        }
      }
      ourStory {
        heading {
          primaryHeading
          preHeading
        }
        text
      }
      meetTheTeam {
        teamListing {
          jobTitle
          name
          department
          pronouns
          avatar {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        title {
          preHeading
          primaryHeading
        }
      }
    }
  }
`

export default AboutPage
